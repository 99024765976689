import { PropsWithChildren } from "react";
import { list } from "./list.styles";

interface SectionListProps {
  title?: string;
}

export const List = ({
  title,
  children,
}: PropsWithChildren<SectionListProps>) => {
  const styles = list();
  return (
    <div className={styles.container()}>
      {title && <h3 className={styles.title()}>{title}</h3>}
      <ul className="flex flex-col gap-1">{children}</ul>
    </div>
  );
};
