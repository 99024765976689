import { ICON_COLORS, ICON_SIZE } from "./icons.constants";
import { IconProps } from "./icons.types";

export const CalendarIcon = ({
  width = ICON_SIZE.WIDTH,
  height = ICON_SIZE.HEIGHT,
  primaryColor = ICON_COLORS.PRIMARY.DEFAULT,
  secondaryColor = ICON_COLORS.SECONDARY.DEFAULT,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 4H17V3C17 2.45 16.55 2 16 2C15.45 2 15 2.45 15 3V4H9V3C9 2.45 8.55 2 8 2C7.45 2 7 2.45 7 3V4H6C4.34 4 3 5.34 3 7V10H21V7C21 5.34 19.66 4 18 4Z"
        fill={primaryColor}
      />
      <path
        d="M3 19C3 20.66 4.34 22 6 22H18C18.21 22 18.41 21.98 18.6 21.94C19.19 21.82 19.71 21.53 20.12 21.12C20.26 20.98 20.38 20.84 20.49 20.68C20.71 20.36 20.86 20 20.94 19.61C20.98 19.41 21 19.21 21 19.01V10.01H3V19.01V19Z"
        fill={secondaryColor}
      />
      <path
        d="M6 15C6.55228 15 7 14.5523 7 14C7 13.4477 6.55228 13 6 13C5.44772 13 5 13.4477 5 14C5 14.5523 5.44772 15 6 15Z"
        fill={primaryColor}
      />
      <path
        d="M10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15Z"
        fill={primaryColor}
      />
      <path
        d="M14 15C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13C13.4477 13 13 13.4477 13 14C13 14.5523 13.4477 15 14 15Z"
        fill={primaryColor}
      />
      <path
        d="M18 15C18.5523 15 19 14.5523 19 14C19 13.4477 18.5523 13 18 13C17.4477 13 17 13.4477 17 14C17 14.5523 17.4477 15 18 15Z"
        fill={primaryColor}
      />
      <path
        d="M6 19C6.55228 19 7 18.5523 7 18C7 17.4477 6.55228 17 6 17C5.44772 17 5 17.4477 5 18C5 18.5523 5.44772 19 6 19Z"
        fill={primaryColor}
      />
      <path
        d="M10 19C10.5523 19 11 18.5523 11 18C11 17.4477 10.5523 17 10 17C9.44772 17 9 17.4477 9 18C9 18.5523 9.44772 19 10 19Z"
        fill={primaryColor}
      />
      <path
        d="M14 19C14.5523 19 15 18.5523 15 18C15 17.4477 14.5523 17 14 17C13.4477 17 13 17.4477 13 18C13 18.5523 13.4477 19 14 19Z"
        fill={primaryColor}
      />
    </svg>
  );
};
