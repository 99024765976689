import { tv } from "tailwind-variants";

export const accordion = tv({
  slots: {
    header: "flex flex-1 items-center justify-between px-4 text-sm font-medium",
  },
  variants: {
    size: {
      small: {
        header: "h-10",
      },
      medium: {
        header: "h-12",
      },
      large: {
        header: "h-14",
      },
      xlarge: {
        header: "h-16",
      },
    },
  },
  defaultVariants: {
    size: "small",
  },
});
