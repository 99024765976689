import * as React from "react";
import { cn } from "~/utils/cn";
import { Badge, Label, Separator } from "./components";
import { WizardProps } from "./wizard.types";

export const Wizard: React.FC<WizardProps> = ({
  steps,
  isMobile,
  className,
}) => {
  const isMultiple = steps.length > 1;

  return (
    <div className={cn("mx-auto w-full p-8", className)}>
      <div
        className={cn(
          "flex items-center",
          isMultiple ? "justify-between" : "justify-center",
        )}
      >
        {steps.map(({ done, label, isCurrent }, i) => {
          const isFirst = i === 0;
          const showLabel = !isMobile || isCurrent;
          const item = (
            <div className="relative flex flex-wrap justify-center">
              {showLabel && <Label done={done}>{label}</Label>}
              <Badge done={done} />
            </div>
          );

          if (isMultiple) {
            return (
              <React.Fragment key={`${label}-${i}-wrapper`}>
                {!isFirst && <Separator done={done} />}
                {item}
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={`${label}-${i}-wrapper`}>
              {item}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
