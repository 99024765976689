import { matchScore } from "./match-score.styles";
import { MatchScoreProps } from "./match-score.types";
import { getBgColor } from "./match-score.utils";

export const MatchScore: React.FC<MatchScoreProps> = ({ score }) => {
  const color = getBgColor(
    score,
  ).toString() as keyof typeof matchScore.variants.color;

  return (
    <div className={matchScore({ color })}>
      <span className="text-sm/6 font-medium tracking-tighter text-neutral-primary">
        {score}
      </span>
    </div>
  );
};
