"use client";

import React, { useMemo } from "react";

import Spinner from "~/components/spinner";
import { TextField } from "~/components/v2/TextField";
import useDebounce from "~/hooks/useDebounce";
import { JobEntry, JobEntryProps } from "..";
import { useGetSideBarRequisitionsQuery } from "~/bff/graphql/generated/graphql";
import { organizeMilestonesData } from "./job-menu-utils";

interface JobMenuProps {
  expand: () => void;
}

export const JobMenu = ({ expand }: JobMenuProps) => {
  const {
    data: requisitionsData,
    loading: isRequisitionsDataLoading,
    refetch: refetchRequisitions,
  } = useGetSideBarRequisitionsQuery();

  const [search, setSearch] = React.useState("");
  const debouncedSearch = useDebounce(search, 500);

  const loading = isRequisitionsDataLoading;

  const companyRequisitions: JobEntryProps[] = useMemo(() => {
    if (requisitionsData?.GetRequisitions?.length) {
      const filteredRequisitions = requisitionsData.GetRequisitions.filter(
        r => r.jobPipelines?.length,
      );
      return filteredRequisitions.map(r => ({
        id: Number(r.id),
        job_title: r.jobName!,
        Pipeline: {
          id: Number(r.jobPipelines?.[0].id),
          // name: r.jobPipelines?.[0].title!,
          name: "Pipeline",
          stages: organizeMilestonesData(
            r.jobPipelines?.[0].TemplatePipeline?.TemplateMilestones || [],
          ).flatMap(m => {
            return (m.TemplateStage || []).map(s => ({
              id: Number(s.id),
              name: s.name!,
              milestoneId: Number(m.id),
              milestoneType: m.name!,
            }));
          }),
        },
      }));
    }
    return [];
  }, [requisitionsData?.GetRequisitions]);

  return (
    <div className="flex flex-1 overflow-auto">
      <div className="flex h-full w-full flex-col gap-4 overflow-y-hidden overflow-x-clip">
        <TextField
          startAdornment={
            <i
              aria-hidden
              className="fa-regular fa-search text-sm text-icons-secondary"
            />
          }
          placeholder="Search"
          value={search}
          onFocus={expand}
          onChange={e => setSearch(e.target.value)}
          className="h-9 rounded-xl border-neutral-10 bg-white pl-9 placeholder:text-neutral-tertiary hover:bg-neutral-10"
          containerClassName="px-4 py-1"
        />
        <ul className="flex h-full flex-1 flex-col gap-1 overflow-y-auto overflow-x-clip">
          {loading ? (
            <Spinner className="relative" />
          ) : (
            <div className="flex flex-col gap-2 px-4">
              {companyRequisitions?.map(job => (
                <JobEntry key={job.id} {...job} />
              ))}
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};
