import { PropsWithChildren } from "react";
import { ButtonIcon } from "../button-icon";
import { ActionButton } from "../button/action";
import {
  AccordionContent,
  AccordionGroup,
  AccordionItem,
  AccordionTrigger,
} from "./accordion-group";
import { AccordionProps, isIconAction, TextAction } from "./accordion.types";
import { accordion } from "./accordion.styles";

export const Accordion = ({
  title,
  titleComponent,
  children,
  startAdornment,
  actions = [],
  actionButtonItems,
  defaultOpen = true,
  collapsible = true,
  padded = true,
  size,
  className,
  contentClassName,
}: PropsWithChildren<AccordionProps>) => {
  const { header } = accordion({ size });

  return (
    <AccordionGroup
      type="single"
      collapsible={collapsible}
      defaultValue={defaultOpen ? "item-1" : ""}
    >
      <AccordionItem className={className} value="item-1">
        <div className={header()}>
          <div className="flex flex-row items-center gap-2">
            {startAdornment}

            {title && (
              <h2 className="text-sm font-medium leading-6 tracking-tighter">
                {title}
              </h2>
            )}

            {titleComponent}
          </div>

          <div className="flex items-center gap-2">
            {actions.length > 0 && (
              <div className="flex items-center gap-1">
                {actions.map(action => {
                  if (isIconAction(action)) {
                    return (
                      <ButtonIcon
                        key={action.icon}
                        onClick={action.onClick}
                        icon={action.icon}
                        aria-label={action?.["aria-label"]}
                      />
                    );
                  }

                  const textAction = action as TextAction;
                  return (
                    <span
                      key={textAction.text}
                      onClick={textAction.onClick}
                      aria-label={textAction?.["aria-label"]}
                      className="font-inter cursor-pointer text-[14px] font-medium leading-[24px] tracking-[-0.6px] text-brand-primary-rest"
                    >
                      {textAction.text}
                    </span>
                  );
                })}
              </div>
            )}

            {actionButtonItems && (
              <ActionButton actionsItems={actionButtonItems} asButtonIcon />
            )}

            {collapsible && <AccordionTrigger />}
          </div>
        </div>

        <AccordionContent
          className={contentClassName}
          hasContentPadding={padded}
        >
          {children}
        </AccordionContent>
      </AccordionItem>
    </AccordionGroup>
  );
};
