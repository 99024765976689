import { ReactNode, useState } from "react";
import { styles } from "./aside.styles";
import { Body, BodySize, Title, TitleSize } from "../typography";
import { DynamicIcon } from "./components/dynamic-icon";
import { SidebarCloseIcon, SidebarOpenIcon } from "./components/icons";

interface AsideState {
  hovered: boolean;
  collapsed: boolean;
  showContent: boolean;
  expand: () => void;
}

interface AsideProps {
  title?: string;
  collapsible: boolean;
  collapsed: boolean;
  children: (state: AsideState) => ReactNode;
}

export const Aside = (props: AsideProps) => {
  const [collapsed, setCollapsed] = useState(props.collapsed);
  const [hovered, setHovered] = useState(false);
  const { aside, nav } = styles({
    collapsed,
    collapsible: props.collapsible,
    hovered: collapsed && hovered,
  });

  const showContent = hovered || !collapsed;
  const toggleText = collapsed ? "Open Navigation" : "Collapse Navigation";

  return (
    <aside className={aside()}>
      <nav
        className={nav()}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {props.title && (
          <div className="border-b border-neutral-00 p-4">
            <Title size={TitleSize.extraSmall}>
              {showContent ? props.title : <>&nbsp;</>}
            </Title>
          </div>
        )}

        {props.children({
          collapsed,
          hovered,
          showContent,
          expand: () => setCollapsed(false),
        })}

        {props.collapsible && (
          <div className="mt-auto border-t border-neutral-00 p-2">
            <button
              className="flex w-full items-center gap-2 rounded-xl p-2 hover:bg-surface-brand-rest"
              onClick={() => setCollapsed(!collapsed)}
            >
              <div className="shrink-0">
                <DynamicIcon
                  icon={collapsed ? SidebarCloseIcon : SidebarOpenIcon}
                />
              </div>
              <Body size={BodySize.small} className="whitespace-nowrap">
                {showContent && toggleText}
              </Body>
            </button>
          </div>
        )}
      </nav>
    </aside>
  );
};
