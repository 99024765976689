import { badgeClassname } from "./badge.styles";
import { BadgeProps, BadgeSize, BadgeType } from "./badge.types";

export const Badge: React.FC<BadgeProps> = ({
  type = BadgeType.brand,
  size = BadgeSize.medium,
  value = 0,
}) => {
  const hasMoreThanTwoDigits = value >= 100;
  const formattedValue = hasMoreThanTwoDigits ? "+99" : value;

  return (
    <div className={badgeClassname({ type, size })}>
      {formattedValue}
    </div>
  );
};
