import { ICON_COLORS, ICON_SIZE } from "./icons.constants";
import { IconProps } from "./icons.types";

export const SidebarCloseIcon = ({
  width = ICON_SIZE.WIDTH,
  height = ICON_SIZE.HEIGHT,
  primaryColor = ICON_COLORS.PRIMARY.DEFAULT,
  secondaryColor = ICON_COLORS.SECONDARY.DEFAULT,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34315 4.34315 3 6 3Z"
        fill={secondaryColor}
      />
      <rect
        width="3"
        height="12"
        rx="1.5"
        transform="matrix(-1 0 0 1 18 6)"
        fill={primaryColor}
      />
    </svg>
  );
};
