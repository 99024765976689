"use client";

import { useRouter } from "next/navigation";
import { JobRequisitionIcon } from "~/scalis-components/core/sidebar";
import { cn } from "~/utils/cn";
import { Body, BodySize } from "~/scalis-components/core/typography";
import { ButtonIcon } from "~/scalis-components/core";
import { Routes } from "~/src/app/_constants/routes";

import { SortByMenu } from "./components";

interface HeaderActionsProps {
  showContent: boolean;
  expand: () => void;
}

export const HeaderActions = ({ showContent, expand }: HeaderActionsProps) => {
  const router = useRouter();

  const handleCreateJobRequisition = () => router.push(Routes.requisition.new);

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <div className="shrink-0 p-2">
          <JobRequisitionIcon />
        </div>

        {showContent && (
          <Body size={BodySize.small} className="whitespace-nowrap">
            Job Requisitions
          </Body>
        )}
      </div>
      <div className={cn("flex gap-2", !showContent && "hidden")}>
        <SortByMenu expand={expand} />
        <ButtonIcon
          icon="fa-regular fa-plus"
          onClick={handleCreateJobRequisition}
        />
      </div>
    </div>
  );
};
