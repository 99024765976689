import { tv } from "tailwind-variants";
import { SwitchColorEnum } from "./switch.types";

export const switchStyle = tv({
  base: "",
  variants: {
    color: {
      [SwitchColorEnum.dark]: "data-[state=checked]:bg-button-dark-00",
      [SwitchColorEnum.green]: "data-[state=checked]:bg-dataviz-score-90",
    },
  },

  defaultVariants: {
    color: SwitchColorEnum.dark,
  },
});
