import { forwardRef } from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { ChangeHandler } from "react-hook-form";

import { cn } from "~/utils/cn";

interface Props
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  onChange?: ChangeHandler;
}

export const CheckboxButton = forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  Props
>(({ className, onChange, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "h-[18px] w-[18px] shrink-0 rounded border border-neutral-rest",
      "focus-visible:ring-ring peer focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
      "disabled:cursor-not-allowed disabled:data-[state=checked]:bg-neutral-disabled ",
      "data-[state=checked]:text-neutral-00 data-[state=checked]:border-0 data-[state=checked]:bg-neutral-primary",
      className,
    )}
    onCheckedChange={value => {
      onChange?.({ target: { value, name: props.name } });
    }}
    {...props}
    aria-label={props["aria-label"]}
    type="button"
  >
    <CheckboxPrimitive.Indicator
      className={"flex h-full w-full items-center justify-center"}
    >
      <i className="fa-solid fa-check fa-xs pt-0.5 text-neutral-on-brand" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
