import {
  DateRange,
  SelectRangeEventHandler,
  SelectSingleEventHandler,
} from "react-day-picker";

interface Props {
  className?: string;
  error?: string;
  ariaLabel: string;
  isDisabled?: boolean;
  label?: string;
  required?: boolean;
}

export interface IDatePicker extends Props {
  date?: Date;
  onSelect: SelectSingleEventHandler;
  mode?: DatepickerMode.Single;
}

export interface IDateRangePicker extends Props {
  date?: DateRange;
  onSelect: SelectRangeEventHandler;
  mode: DatepickerMode.Range;
}

export type DatePickerProps = IDatePicker | IDateRangePicker;

export enum DatepickerMode {
  Single = "single",
  Range = "range",
}
