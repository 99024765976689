import React from "react";
import { ICON_COLORS, ICON_SIZE } from "./icons.constants";
import { IconProps } from "./icons.types";

const JobRequisitionIconComponent = ({
  width = ICON_SIZE.WIDTH,
  height = ICON_SIZE.HEIGHT,
  primaryColor = ICON_COLORS.PRIMARY.DEFAULT,
  secondaryColor = ICON_COLORS.SECONDARY.DEFAULT,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 7V6C18 4.35 16.65 3 15 3H9C7.35 3 6 4.35 6 6V7C4.34 7 3 8.34 3 10V15C3 15 7 17 12 17C17 17 21 15 21 15V10C21 8.34 19.66 7 18 7ZM16 7H8V6C8 5.45 8.45 5 9 5H15C15.55 5 16 5.45 16 6V7Z"
        fill={primaryColor}
      />
      <path
        d="M3 15V18C3 19.66 4.34 21 6 21H18C19.66 21 21 19.66 21 18V15C21 15 17 17 12 17C7 17 3 15 3 15Z"
        fill={secondaryColor}
      />
      <path
        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
        fill={secondaryColor}
      />
    </svg>
  );
};

export const JobRequisitionIcon = React.memo(JobRequisitionIconComponent);
