"use client";

import { createContext, useState } from "react";
import {
  SidebarContextProps,
  SidebarProviderProps,
} from "./sidebar-context.types";

export const SidebarContext = createContext({} as SidebarContextProps);

export const SidebarContextProvider: React.FC<SidebarProviderProps> = ({
  children,
}) => {
  const [focused, setFocused] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const isActive = focused || isHovered;

  const toggleCollapsed = () => {
    setCollapsed(isCollapsed => !isCollapsed);
  };

  return (
    <SidebarContext.Provider
      value={{
        collapsed,
        isActive,
        toggleCollapsed,
        setFocused,
        setIsHovered,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
