import * as React from "react";

import { SwitchButton } from "./switch-button";
import { SwitchProps } from "./switch.types";
import { cn } from "~/utils/cn";
import { switchStyle } from "./switch.styles";
import { Tooltip, TooltipProvider, DefaultInfoIcon } from "../tooltip";

export const Switch: React.FC<SwitchProps> = ({
  label,
  color,
  tooltip,
  ...props
}) => {
  return (
    <div className="flex items-center gap-2">
      <TooltipProvider>
        <SwitchButton {...props} className={switchStyle({ color })} />
        {label && (
          <label
            htmlFor={props.id}
            className={cn(
              "select-none text-sm/6 font-normal text-neutral-primary",
              props.disabled && "text-neutral-disabled",
            )}
          >
            {label}
            {tooltip && (
              <Tooltip
                content={tooltip}
                tooltipTrigger={<DefaultInfoIcon className="ml-1" />}
              />
            )}
          </label>
        )}
      </TooltipProvider>
    </div>
  );
};
