import { ToastProps } from "./notification-group";
import { actionTypes } from "./notification.constants";

export enum NotificationKind {
  warning = "warning",
  danger = "danger",
  success = "success",
  info = "info",
}

interface NotificationAction {
  label: string;
  onClick: () => void;
}

export type NotificationProps = {
  id: string;
  title: string;
  description?: string;
  action?: NotificationAction;
  kind: NotificationKind;
};

type ActionType = typeof actionTypes;

export type UseNotificationProps = NotificationProps & ToastProps;

export type UseNotificationAction =
  | {
      type: ActionType["ADD_NOTIFICATION"];
      notification: UseNotificationProps;
    }
  | {
      type: ActionType["UPDATE_NOTIFICATION"];
      notification: Partial<UseNotificationProps>;
    }
  | {
      type: ActionType["DISMISS_NOTIFICATION"];
      notificationId?: UseNotificationProps["id"];
    }
  | {
      type: ActionType["REMOVE_NOTIFICATION"];
      notificationId?: UseNotificationProps["id"];
    };
