export const SORT_BY_NEWEST_TO_OLDEST = "newestToOldest";
export const SORT_BY_OLDEST_TO_NEWEST = "oldestToNewest";
export const SORT_BY_A_TO_Z = "aToZ";
export const SORT_BY_Z_TO_A = "zToA";
export const SORT_BY_MOST_APPLICANTS = "mostApplicants";
export const SORT_BY_LEAST_APPLICANTS = "leastApplicants";

export const SORT_BY_OPTIONS = [
  { label: "Newest to Oldest", value: SORT_BY_NEWEST_TO_OLDEST },
  { label: "Oldest to Newest", value: SORT_BY_OLDEST_TO_NEWEST },
  { label: "A to Z", value: SORT_BY_A_TO_Z },
  { label: "Z to A", value: SORT_BY_Z_TO_A },
  { label: "Most Applicants", value: SORT_BY_MOST_APPLICANTS },
  { label: "Least Applicants", value: SORT_BY_LEAST_APPLICANTS },
];
