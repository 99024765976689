import { CheckboxButton } from "./checkbox-button";
import { CheckboxProps } from "./checkbox.types";
import { cn } from "~/utils/cn";

export const Checkbox = ({ label, ...props }: CheckboxProps) => {
  return (
    <div className="flex items-center gap-2">
      <CheckboxButton {...props} />
      {label && (
        <label
          htmlFor={props.id}
          className={cn(
            "select-none text-sm/6 font-normal text-neutral-primary",
            props.disabled && "text-neutral-disabled",
          )}
        >
          {label}
        </label>
      )}
    </div>
  );
};
