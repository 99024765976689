import { RadioGroupBase, RadioGroupItem } from "../radio-group-base";
import { RadioGroupProps, RadioGroupType } from "./radio-group.types";
import { cn } from "~/utils/cn";
import { radioGroupStyles } from "./radio-group.styles";
import { Tooltip, TooltipProvider, DefaultInfoIcon } from "../../tooltip";

export const RadioGroup = ({
  options,
  error,
  name,
  type = RadioGroupType.Default,
  optionsContainerClassName,
  optionContainerClassName,
  buttonClassName,
  labelClassName,
  ...props
}: RadioGroupProps) => {
  const { container, label } = radioGroupStyles({
    type,
    disabled: props.disabled,
  });

  return (
    <RadioGroupBase {...props}>
      <div
        className={cn(
          {
            "grid auto-cols-fr grid-flow-col gap-2":
              type === RadioGroupType.Outlined,
          },
          optionsContainerClassName,
        )}
      >
        {options.map(option => {
          const id = `${name}-${option.value}`;

          return (
            <div
              key={option.value}
              className={cn(
                container({ checked: option.value === props.value }),
                `${type === RadioGroupType.Highlited && props.value === option.value ? "bg-slate-100" : ""}`,
              )}
            >
              <RadioGroupItem
                value={option.value}
                id={id}
                aria-valuetext={option.value}
                aria-label={String(option.label)}
                data-testid={option.value}
                className={buttonClassName}
              />

              <div className={cn("flex flex-col")}>
                <TooltipProvider>
                  <label
                    htmlFor={id}
                    className={label({ class: labelClassName })}
                  >
                    {option.label}

                    {option.tooltip && (
                      <Tooltip
                        content={option.tooltip}
                        tooltipTrigger={<DefaultInfoIcon className="ml-1" />}
                      />
                    )}

                    {option.endAdornment}
                  </label>
                </TooltipProvider>

                {option.description && (
                  <span className="select-none text-sm/6 text-neutral-secondary">
                    {option.description}
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {error && (
        <span className="mt-1 inline-block text-xs text-status-critical">
          {error}
        </span>
      )}
    </RadioGroupBase>
  );
};
