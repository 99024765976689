import * as React from "react";
import { DEFAULT_ICON_CONFIG } from "./dynamic-icon.constants";
import { DynamicIconProps } from "./dynamic-icon.types";

export const DynamicIcon = ({
  icon: Icon,
  iconConfig = DEFAULT_ICON_CONFIG,
  isActive,
}: DynamicIconProps) => {
  const currentPrimaryColor = isActive
    ? iconConfig.primaryColor?.active!
    : iconConfig.primaryColor?.default!;

  const currentSecondaryColor = isActive
    ? iconConfig.secondaryColor?.active!
    : iconConfig.secondaryColor?.default!;

  return (
    <Icon
      width={iconConfig.width}
      height={iconConfig.height}
      primaryColor={currentPrimaryColor}
      secondaryColor={currentSecondaryColor}
    />
  );
};
