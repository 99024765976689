"use client";

import { useRef, useState } from "react";

import { Star } from "./components";
import { StarRatingProps } from "./star-rating.types";
import { cn } from "~/utils/cn";

export const StarRating = ({
  rating,
  totalStars = 5,
  onClick,
}: StarRatingProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [hoverValue, setHoverValue] = useState(0);

  const starsArray = [...Array(totalStars)];

  const calculateRating = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!ref?.current || !onClick) return;

    const { width, left } = ref.current.getBoundingClientRect();
    const amountOfStars = ((e.clientX - left) / width) * totalStars;
    const result = Math.round(amountOfStars * 2) / 2;
    setHoverValue(result);
  };

  const getIndexRating = (index: number) => {
    const value = isHovered ? hoverValue : rating;
    const diff = value - index;

    if (diff >= 1) return 1;
    if (diff <= 0) return 0;
    return diff;
  };

  const onMouseEnter = () => {
    if (onClick) return setIsHovered(true);
  };

  const onMouseLeave = () => {
    if (onClick) return setIsHovered(false);
  };

  return (
    <div
      ref={ref}
      className={cn("flex", {
        "cursor-pointer": !!onClick,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMoveCapture={calculateRating}
      onClick={() => onClick?.(hoverValue)}
    >
      {starsArray.map((_, index) => (
        <Star
          key={index}
          status={getIndexRating(index)}
          clickable={!!onClick}
        />
      ))}
    </div>
  );
};
