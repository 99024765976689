import { ICON_COLORS, ICON_SIZE } from "./icons.constants";
import { IconProps } from "./icons.types";

export const TalentPoolIcon = ({
  width = ICON_SIZE.WIDTH,
  height = ICON_SIZE.HEIGHT,
  primaryColor = ICON_COLORS.PRIMARY.DEFAULT,
  secondaryColor = ICON_COLORS.SECONDARY.DEFAULT,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2505 8.18159C20.7474 6.9599 20.0185 5.87168 19.0842 4.93744C18.1499 4.00321 17.0513 3.27431 15.8398 2.77126C13.3963 1.74463 10.614 1.74463 8.17043 2.76099C6.94866 3.26404 5.86037 3.99295 4.92608 4.92718C3.99179 5.86141 3.26283 6.94963 2.75975 8.17132C2.25667 9.39301 2 10.6763 2 12.0006C2 13.325 2.25667 14.6083 2.75975 15.83C3.26283 17.0517 3.99179 18.1399 4.92608 19.0741C5.86037 20.0083 6.94866 20.7372 8.17043 21.2403C9.39219 21.7433 10.6858 22 12 22C13.3142 22 14.6078 21.7433 15.8296 21.2403C17.0513 20.7372 18.1396 20.0083 19.0739 19.0741C20.0082 18.1399 20.7372 17.0517 21.2402 15.83C21.7433 14.6083 22 13.3147 22 12.0006C22 10.6866 21.7433 9.39301 21.2402 8.17132L21.2505 8.18159Z"
        fill={primaryColor}
      />
      <path
        d="M8.92017 9.95737C8.92017 10.7787 9.24871 11.5589 9.82365 12.1338C10.3986 12.7087 11.1892 13.0373 12.0002 13.0373C12.8113 13.0373 13.6019 12.7087 14.1768 12.1338C14.7518 11.5589 15.0803 10.7684 15.0803 9.95737C15.0803 9.14633 14.7518 8.35583 14.1768 7.78092C13.6019 7.20601 12.8113 6.87749 12.0002 6.87749C11.1892 6.87749 10.3986 7.20601 9.82365 7.78092C9.24871 8.35583 8.92017 9.14633 8.92017 9.95737Z"
        fill={secondaryColor}
      />
      <path
        d="M13.9919 15.0905H9.88508C8.14997 15.0905 6.63046 15.9529 5.69617 17.2772C5.85017 17.462 6.01444 17.6468 6.18898 17.8213C6.959 18.5913 7.85223 19.1868 8.85839 19.5974C10.8604 20.429 13.1397 20.429 15.1418 19.5974C16.1479 19.1868 17.0411 18.581 17.8112 17.8213C17.9549 17.6776 18.0986 17.5133 18.2321 17.3594C17.3081 15.9939 15.7578 15.0905 13.9919 15.0905Z"
        fill={secondaryColor}
      />
    </svg>
  );
};
