import { ButtonHTMLAttributes } from "react";

export enum BadgeType {
  subtle = "subtle",
  brand = "brand",
  danger = "danger",
}

export enum BadgeSize {
  large = "large",
  medium = "medium",
};

export interface BadgeProps
  extends Omit<Partial<ButtonHTMLAttributes<HTMLButtonElement>>, "type"> {
  type?: BadgeType;
  size?: BadgeSize;
  value?: number;
}
