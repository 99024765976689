"use client";

import { cn } from "~/utils/cn";
import { useSidebar } from "../../context";
import { containerClassName, navClassName } from "./container.styles";

export const Container: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  const { collapsed, isActive, setIsHovered } = useSidebar();

  return (
    <aside
      className={cn(containerClassName({ open: !collapsed }), className)}
      {...props}
    >
      <nav
        className={navClassName({ openByActivity: isActive })}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        tabIndex={collapsed ? 0 : -1}
        {...props}
      >
        {children}
      </nav>
    </aside>
  );
};
