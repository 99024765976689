import Link from "next/link";
import { Children, cloneElement, isValidElement } from "react";
import { cn } from "~/utils/cn";

import { Body, BodySize, BodyType } from "../typography";
import { TabsContent, TabsList, TabsRoot, TabsTrigger } from "./tabs-base";
import { TabProps, TabsProps } from "./tabs.types";

export const Tabs = ({
  tabs,
  variant = "primary",
  className,
  tabsListClassName,
  tabsContentClassName,
  orientation,
  children,
}: TabsProps) => {
  const defaultTabId = tabs.find(tab => tab.isActive)?.id ?? tabs[0].id;
  const isVertical = orientation === "vertical";

  const renderTriggerBody = ({ title, icon }: TabProps) => {
    const showSecondaryIcon = variant === "secondary" && icon;

    return (
      <div className="text-center">
        {showSecondaryIcon && (
          <div className="mb-1 inline-flex h-10 w-10 items-center justify-center rounded-xl p-2 text-icon-neutral-00 group-data-[state=active]:bg-brand-primary-dark-00 group-data-[state=inactive]:bg-gray-200">
            <i
              className={cn(
                icon,
                "w-4 text-base font-normal group-data-[state=inactive]:text-neutral-disabled",
              )}
            />
          </div>
        )}
        <Body
          size={BodySize.small}
          type={isVertical ? BodyType.basic : BodyType.emphasys}
          className={cn(
            "text-neutral-secondary group-data-[state=active]:text-neutral-primary",
            isVertical &&
              "text-neutral-primary group-data-[state=active]:font-medium group-data-[state=active]:text-brand-primary-10",
            variant === "secondary" &&
              "text-neutral-tertiary group-data-[state=active]:text-scalis-logo-color",
          )}
        >
          {title}
        </Body>
      </div>
    );
  };

  const tabsListClasses = cn(
    "whitespace-nowrap",
    !isVertical && "min-h-fit overflow-x-auto overflow-y-hidden",
    isVertical && "flex flex-col overflow-y-auto border-b-0 border-r",
    variant === "secondary" && "flex justify-evenly",
    tabsListClassName,
  );

  const renderTabContent = () => {
    if (children) {
      return Children.map(children, child => {
        if (!isValidElement(child) || child.type !== TabsContent) return null;
        return tabs.some(tab => tab.id === (child.props as any).value)
          ? cloneElement(child)
          : null;
      });
    }

    if (tabs.every(tab => tab.href == null)) {
      return (
        <div className="flex flex-grow overflow-x-hidden">
          {tabs.map(tab => (
            <TabsContent
              className={cn("w-full", tabsContentClassName)}
              key={`tab-content-${tab.id}`}
              value={tab.id}
            >
              {tab.content}
            </TabsContent>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <TabsRoot
      className={cn("flex w-full gap-4", !isVertical && "flex-col", className)}
      defaultValue={defaultTabId}
      orientation={orientation}
    >
      <TabsList className={tabsListClasses}>
        {tabs.map(tab => (
          <TabsTrigger
            key={`tab-header-${tab.id}`}
            value={tab.id}
            asChild={tab.href != null}
            className={cn(
              "group",
              isVertical &&
                "justify-start data-[state=active]:border-b-0 data-[state=active]:bg-brand-primary-light-10",
            )}
          >
            {tab.href ? (
              <Link href={tab.href}>{renderTriggerBody(tab)}</Link>
            ) : (
              renderTriggerBody(tab)
            )}
          </TabsTrigger>
        ))}
      </TabsList>

      {renderTabContent()}
    </TabsRoot>
  );
};
