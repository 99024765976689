import { tv } from "tailwind-variants";

export const datePickerClassName = tv({
  base: "flex w-full items-center gap-2 rounded-lg border border-bg-neutral-10 px-3 py-2 text-neutral-tertiary",
  variants: {
    error: {
      true: "border-status-critical",
    },
  },
});
