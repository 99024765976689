export const ICON_SIZE = {
  WIDTH: 24,
  HEIGHT: 24,
} as const;

export const ICON_COLORS = {
  PRIMARY: {
    DEFAULT: "#0021F8",
    ACTIVE: "#0021F8",
  },
  SECONDARY: {
    DEFAULT: "#BFCFFA",
    ACTIVE: "#FFFFFF",
  },
} as const;
