import { Routes } from "~/src/app/_constants/routes";
import { LEVELS } from "../job-entry";
import { JobMenuItemProps } from "./job-menu-item.types";

export const getHref = (
  level: JobMenuItemProps["level"],
  id: number,
  jobId: number,
  milestoneId?: number,
  pipelineId?: number,
) => {
  switch (level) {
    case LEVELS.job:
      return `${Routes.requisition.edit({ requisitionId: jobId})}${Routes.requisition.requisitionDetails}`;
    case LEVELS.pipeline:
      return `${Routes.job_listing.pipeline({ requisitionId: jobId })}`;
    case LEVELS.stage:
      return  `${Routes.job_listing.stage({ requisitionId: jobId, milestoneId: milestoneId!, stageId: id })}`;
    default:
      "/";
  }
};
