"use client";

import { useMemo } from "react";

export const useIsHighlighted = (id: number, searchParams: URLSearchParams) => {
  const stageIdParam = searchParams.get("stageId");
  const pipelineIdParam = searchParams.get("pipelineId");
  const jobIdParam = searchParams.get("jobId");

  return useMemo(() => {
    return (
      Number(stageIdParam) === id ||
      Number(pipelineIdParam) === id ||
      Number(jobIdParam) === id
    );
  }, [id, stageIdParam, pipelineIdParam, jobIdParam]);
};
