import * as React from "react";

import { SwitchButton } from "./switch-button";

type SwitchButtonButtonProps = React.ComponentProps<typeof SwitchButton>;

export type SwitchProps = SwitchButtonButtonProps & {
  label?: string;
  color?: SwitchColorEnum;
  tooltip?: string;
  onCheckedChange?(checked: boolean): void; // duplicate of SwitchButtonButtonProps.onCheckedChange just for the sake of autocomplete
};

export enum SwitchColorEnum {
  green = "green",
  dark = "dark",
}
