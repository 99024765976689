import { TemplateMilestone } from "~/bff/graphql/generated/graphql";

export const organizeMilestonesData = (
  templateMilestones: TemplateMilestone[],
) => {
  if (!templateMilestones?.length) return [];
  const sortedMilestones = templateMilestones
    ?.map(m => ({
      ...m,
      TemplateStage: m.TemplateStage ? [...m.TemplateStage] : [],
    }))
    .sort((a, b) => (a.rankOrder ?? 0) - (b.rankOrder ?? 0))
    .map(m => {
      const sortedStages = m.TemplateStage?.sort(
        (a, b) => (a.rankOrder ?? 0) - (b.rankOrder ?? 0),
      );
      return { ...m, TemplateStage: sortedStages };
    });
  return sortedMilestones;
};
