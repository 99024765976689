"use client";

import * as React from "react";
import Link from "next/link";
import { cn } from "~/utils/cn";
import { Body, BodySize } from "~/scalis-components/core/typography";

import { DynamicIcon } from "../dynamic-icon";
import { ItemProps } from "./item.types";

export const Item = ({
  label,
  href,
  icon,
  active,
  showContent = true,
}: ItemProps) => {
  return (
    <li>
      <Link
        href={href}
        prefetch
        className={cn(
          "block rounded-xl p-2",
          active ? "bg-surface-brand-hover" : "hover:bg-surface-brand-rest",
        )}
        aria-current={active ? "page" : undefined}
      >
        <div className="flex items-center gap-2">
          <div className={cn("shrink-0", active && "text-brand-primary-rest")}>
            <DynamicIcon icon={icon} isActive={active} />
          </div>

          {showContent && (
            <>
              <Body
                size={BodySize.small}
                className={cn(
                  "whitespace-nowrap",
                  active && ["text-brand-primary-rest", "font-medium"],
                )}
              >
                {label}
              </Body>
              {active && (
                <div className="ml-auto h-4 w-[3px] rounded bg-brand-primary-rest" />
              )}
            </>
          )}
        </div>
      </Link>
    </li>
  );
};
